// Copyright 2024 Descript, Inc

import {
    useDailyEvent,
    useLocalSessionId,
    useParticipantProperty,
} from '@daily-co/daily-react';
import { useCallback, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import { usePubNub } from '../contexts/PubNubProvider';
import {
    RecordingAnalyticsEvents,
    RecordingAnalyticsModalType,
    WebRecorderSegmentMetadata,
} from '@descript/recorder-base';
import { useMeetingSessionData } from '@features/breakouts/hooks/useMeetingSessionData';
import { CallSessionData } from '@contexts/CallProvider';
import { useAnalytics } from '@contexts/AnalyticsProvider';
import { useParticipantsUploadingState, useUploadingState } from '../state/uploadProgress';
import { UserUploadState } from '../types/uploadProgress';
import { broadcastArtifactProgress, broadcastOverallProgress } from '../api/uploadProgress';

export const useRecordingUploadProgress = () => {
    const { trackWithCallProps } = useAnalytics();
    const [uploadState, setUploadState] = useUploadingState();
    const [participantUploadState, setParticipantsState] = useParticipantsUploadingState();
    const { addListeners, isReady, removeListeners } = usePubNub();
    const [isBroadcasting, setIsBroadcasting] = useState(false);
    const [isRecordingStopped, setIsRecordingStopped] = useState(false);
    const localSessionId = useLocalSessionId();
    const { sessionData } = useMeetingSessionData<CallSessionData>();
    const [isOwner, participantName] = useParticipantProperty(localSessionId, [
        'owner',
        'user_name',
    ]);
    const [localRecordersProgress, setLocalRecordersProgress] = useState<{
        [recorderId: string]: {
            uploadedSegments: number;
            totalSegments: number;
            committed: boolean;
        };
    }>({});
    const [, setSomeoneHasStalled] = useState<boolean>(false);

    useEffect(() => {
        const allStates = [uploadState, ...Object.values(participantUploadState)];
        setSomeoneHasStalled((wasStalled) => {
            const nowStalled = allStates.some((s) => s.status === 'stalled');
            if (nowStalled && !wasStalled) {
                trackWithCallProps(RecordingAnalyticsEvents.modal_shown, {
                    modal: RecordingAnalyticsModalType.participants_stalled,
                });
            }
            return nowStalled;
        });
    }, [setSomeoneHasStalled, uploadState, participantUploadState, trackWithCallProps]);

    /**
     *  Add identity info to uploadState whenever its set (likely only once initially).
     *  This maintains the info if we leave the call (where sessionData gets reset).
     */
    useEffect(() => {
        if (!participantName || !localSessionId || isOwner === undefined) {
            return;
        }

        setUploadState((state) => ({
            ...state,
            id: localSessionId,
            name: participantName,
            owner: isOwner,
        }));
    }, [participantName, localSessionId, isOwner, setUploadState]);

    /**
     * (Owner only)
     * Update a participant's state when a PubNub signal is received
     */
    const signalListener = useCallback(
        (event: PubNub.SignalEvent) => {
            if (
                event.message?.type !== 'roomsParticipantUploadProgress' ||
                !event.message?.participantId
            ) {
                return;
            }

            setParticipantsState((prevState) => {
                // ignore any signals that arrive after we've reached a 'complete' state
                if (prevState[event.message?.participantId]?.status === 'complete') {
                    return prevState;
                }

                return {
                    ...prevState,
                    [event.message?.participantId]: {
                        id: event.message?.participantId,
                        name: event.message?.participantName,
                        percent: event.message?.progress,
                        estRemaining: event.message?.estRemaining,
                        updatedAt: performance.now(),
                        status: 'active',
                    },
                };
            });
        },
        [setParticipantsState],
    );

    /**
     * (Owner only)
     * Update a participant's state when a PubNub message is received (i.e the final committed message)
     */
    const messageListener = useCallback(
        (event: PubNub.MessageEvent) => {
            if (
                event.message?.type !== 'roomsParticipantUploadProgress' ||
                !event.message?.participantId
            ) {
                return;
            }

            setParticipantsState((prevState) => ({
                ...prevState,
                [event.message?.participantId]: {
                    id: event.message?.participantId,
                    name: event.message?.participantName,
                    percent: event.message?.progress,
                    estRemaining: event.message?.estRemaining,
                    updatedAt: performance.now(),
                    status: event.message.progress < 100 ? 'active' : 'complete',
                },
            }));
        },
        [setParticipantsState],
    );

    /**
     * Register listeners with PubNub
     */
    useEffect(() => {
        if (isReady) {
            addListeners({ message: messageListener, signal: signalListener });
        }

        return () => removeListeners({ message: messageListener, signal: signalListener });
    }, [addListeners, removeListeners, messageListener, signalListener, isReady]);

    /**
     * (Owner only)
     * Check for stalled participants every 5 seconds (during and after a recording)
     */
    useEffect(() => {
        if (!uploadState.owner || (!isBroadcasting && !isRecordingStopped)) {
            return;
        }

        const interval = setInterval(
            () =>
                setParticipantsState((state) => {
                    const now = performance.now();
                    const newState = {};
                    let needsNextCheck = false;

                    for (const key in state) {
                        const currentState = state[key];
                        if (!currentState) {
                            continue;
                        }

                        if (['initial', 'complete'].includes(currentState.status)) {
                            newState[key] = currentState;
                            continue;
                        }

                        needsNextCheck = true;

                        newState[key] = {
                            ...currentState,
                            status:
                                now - currentState.updatedAt > 30000
                                    ? 'stalled'
                                    : currentState.status,
                        };
                    }

                    if (!needsNextCheck) {
                        clearInterval(interval);
                        return state;
                    }

                    return newState;
                }),
            5000,
        );

        return () => clearInterval(interval);
    }, [setParticipantsState, uploadState.owner, isBroadcasting, isRecordingStopped]);

    /**
     * Reset values when we start a new recording
     */
    useDailyEvent(
        'recording-started',
        useCallback(() => {
            setUploadState((state) => ({
                ...state,
                totalSegments: 0,
                lastUploadedSegment: 0,
                updatedAt: performance.now(),
                estRemaining: -1,
                status: 'active',
            }));
            setLocalRecordersProgress({});
            setParticipantsState({});
            setIsBroadcasting(true);
            setIsRecordingStopped(false);
        }, [
            setUploadState,
            setParticipantsState,
            setLocalRecordersProgress,
            setIsBroadcasting,
            setIsRecordingStopped,
        ]),
    );

    /**
     * Delay recordingStopped state to let last chunks get registered first
     */
    useDailyEvent(
        'recording-stopped',
        useCallback(() => {
            setTimeout(() => setIsRecordingStopped(true), 750);
        }, [setIsRecordingStopped]),
    );

    /**
     * (Guests Only)
     * Send overall progress to PubNub during and after a recording until 'complete' state is reached
     */
    useEffect(() => {
        if (!isBroadcasting) {
            return;
        }

        const isDone = uploadState.status === 'complete';

        if (!uploadState.owner) {
            broadcastOverallProgress({
                uploadState,
                projectId: sessionData?.cs?.projectId,
                delegateToken: sessionData?.cs?.delegateToken,
                isDone,
            });
        }

        if (isDone) {
            setIsBroadcasting(false);
        }
    }, [
        uploadState,
        setIsBroadcasting,
        isBroadcasting,
        sessionData?.cs?.projectId,
        sessionData?.cs?.delegateToken,
    ]);

    /**
     * Watch localRecordersProgress and mark the user's
     * uploadState as 'complete' when all recorders have committed = true
     */
    useEffect(() => {
        const recordersProgress = Object.values(localRecordersProgress);

        if (
            recordersProgress.length &&
            recordersProgress.every((recorder) => recorder.committed)
        ) {
            setUploadState((state) => ({ ...state, status: 'complete' }));
            trackWithCallProps(RecordingAnalyticsEvents.recording_completed, {});
        }
    }, [setUploadState, localRecordersProgress, trackWithCallProps]);

    /**
     * Public callback for when a recorder has committed its artifact successfully
     */
    const onArtifactCommitted = useCallback(
        (recorderId: string) =>
            setLocalRecordersProgress((current) => ({
                ...current,
                [recorderId]: {
                    ...(current[recorderId] ?? { totalSegments: 0, uploadedSegments: 0 }),
                    committed: true,
                },
            })),
        [setLocalRecordersProgress],
    );

    /**
     * Public callback for when a recorder receives a new segment
     */
    const incrementTotalSegmentCount = useCallback(
        (recorderId: string, incrementBy: number = 1) => {
            setLocalRecordersProgress((current) => {
                const recorder = current[recorderId];

                if (!recorder) {
                    return {
                        ...current,
                        [recorderId]: {
                            totalSegments: incrementBy,
                            uploadedSegments: 0,
                            committed: false,
                        },
                    };
                }

                return {
                    ...current,
                    [recorderId]: {
                        ...recorder,
                        totalSegments: recorder.totalSegments + incrementBy,
                    },
                };
            });

            setUploadState((current) => {
                const percent = Math.round(
                    (current.lastUploadedSegment / (current.totalSegments + incrementBy || 1)) *
                        100,
                );

                return {
                    ...current,
                    percent,
                    totalSegments: current.totalSegments + incrementBy,
                };
            });
        },
        [setUploadState, setLocalRecordersProgress],
    );

    /**
     * Public callback for when a recorder has uploaded a segment successfully
     */
    const incrementLastSegmentUploaded = useCallback(
        (metadata: WebRecorderSegmentMetadata, delegateToken?: string) => {
            const { recorderId } = metadata;

            setLocalRecordersProgress((current) => {
                const recorder = current[recorderId];

                if (!recorder) {
                    return current;
                }

                const progress =
                    (recorder.uploadedSegments + 1) / (recorder.totalSegments || 1);

                broadcastArtifactProgress({ metadata, delegateToken, progress });

                return {
                    ...current,
                    [recorderId]: {
                        ...recorder,
                        uploadedSegments: recorder.uploadedSegments + 1,
                    },
                };
            });

            setUploadState((currentProgress) => {
                const { lastUploadedSegment, totalSegments, updatedAt } = currentProgress;
                const currentPercent = lastUploadedSegment / totalSegments;
                const newPercent = (lastUploadedSegment + 1) / totalSegments;
                const remainingPercent = 1 - newPercent;
                const now = performance.now();
                const timeSinceUpdate = now - updatedAt;

                const newEstimate =
                    (remainingPercent * timeSinceUpdate) / (currentPercent || 1);

                return {
                    ...currentProgress,
                    percent: Math.round(newPercent * 100),
                    lastUploadedSegment: lastUploadedSegment + 1,
                    updatedAt: now,
                    estRemaining: newEstimate,
                };
            });
        },
        [setUploadState, setLocalRecordersProgress],
    );

    /**
     * (Analytics)
     * Tracking all uploads complete event
     */
    useEffect(() => {
        if (!uploadState.owner || !isRecordingStopped) {
            return;
        }

        const isDone = (state: UserUploadState) => state.status === 'complete';

        if ([uploadState, ...Object.values(participantUploadState)].every(isDone)) {
            trackWithCallProps(RecordingAnalyticsEvents.all_uploads_completed, {});
        }
    }, [uploadState, participantUploadState, isRecordingStopped, trackWithCallProps]);

    return {
        incrementTotalSegmentCount,
        incrementLastSegmentUploaded,
        onArtifactCommitted,
    };
};

// Copyright 2024 Descript, Inc

import * as ApiClient from '@descript/client/src/Api/ApiClient';
import { errorCategoryContext } from '@descript/analytics';
import { getWebRecordingSession } from '@descript/recorder-shared';
import { WebRecorderSegmentMetadata } from '@descript/recorder-base';
import { trackError } from '@descript/client/src/Utilities/ErrorTracker';
import { ErrorCategory } from '@descript/errors';
import { WebAssetSyncApi } from '@descript/client/src/Api/WebAssetsSyncApi';
import { UserUploadState } from '../types/uploadProgress';

const defaultCtx = errorCategoryContext(ErrorCategory.Recording);
const assetSyncApi = new WebAssetSyncApi();

type BroadcastArtifactProgressParams = {
    metadata: WebRecorderSegmentMetadata;
    progress: number;
    delegateToken?: string;
};

export function broadcastArtifactProgress({
    metadata,
    progress,
    delegateToken,
}: BroadcastArtifactProgressParams) {
    const webRecordingSession = getWebRecordingSession(metadata.recordingSessionId);
    const projectId = webRecordingSession?.projectId;
    const sessionRecorderMetadata = webRecordingSession?.recorders.get(metadata.recorderId);

    if (!projectId) {
        console.warn('Cant broadcast progress update, no projectId found');
        return;
    }

    if (delegateToken) {
        ApiClient.request(
            defaultCtx(),
            ApiClient.RequestType.POST,
            `/recordings/collaborative/guest/${projectId}/progress`,
            undefined,
            {
                assetId: sessionRecorderMetadata.assetGuid,
                artifactId: sessionRecorderMetadata.artifactGuid,
                progress,
            },
            undefined,
            undefined,
            undefined,
            undefined,
            delegateToken,
        ).catch((e) => {
            trackError(
                e,
                `Failed to send upload progress for recorder: ${metadata.recorderId}`,
                {
                    category: ErrorCategory.Recording,
                },
            );
        });
    } else {
        assetSyncApi
            .updateArtifactProgress(defaultCtx(), {
                projectId,
                assetGuid: sessionRecorderMetadata.assetGuid,
                artifactGuid: sessionRecorderMetadata.artifactGuid,
                progress,
            })
            .catch((e) => {
                trackError(
                    e,
                    `Failed to send upload progress for recorder: ${metadata.recorderId}`,
                    {
                        category: ErrorCategory.Recording,
                    },
                );
            });
    }
}

type BroadcastOverallProgressParams = {
    uploadState: UserUploadState;
    projectId: string;
    delegateToken: string;
    isDone?: boolean;
};

export function broadcastOverallProgress({
    uploadState,
    projectId,
    delegateToken,
    isDone,
}: BroadcastOverallProgressParams) {
    ApiClient.request(
        defaultCtx(),
        ApiClient.RequestType.POST,
        `/recordings/collaborative/guest/${projectId}/overall-progress`,
        undefined,
        {
            participantId: uploadState.id,
            participantName: uploadState.name,
            estRemaining: uploadState.estRemaining,
            progress: uploadState.percent,
            forceMessage: isDone, // make sure the last 100% progress ping is sent as a PubNub message
        },
        undefined,
        undefined,
        undefined,
        undefined,
        delegateToken,
    ).catch((e) => {
        trackError(
            e,
            `Failed to send upload progress for participant: ${uploadState.name}: ${uploadState.id}`,
            {
                category: ErrorCategory.Recording,
            },
        );
    });
}

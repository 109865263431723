// Copyright 2024 Descript, Inc

import { ThemeProvider } from '@daily/shared/src/contexts/Theme';
import { RecoilRoot } from 'recoil';
import { Layout } from '@components/App/Layout';
import { useIframeLauncher } from '@hooks/iframe/useIframeLauncher';
import { Recovery } from '@components/Recovery/Recovery';
import { AnalyticsProvider } from '@contexts/AnalyticsProvider';
import { TracingProvider } from '@contexts/TracingProvider';

export default function IndexPage() {
    const { darkColors, lightColors } = useIframeLauncher();

    return (
        <ThemeProvider colors={lightColors} darkColors={darkColors}>
            <RecoilRoot>
                <AnalyticsProvider>
                    <TracingProvider>
                        <Layout>
                            <Recovery />
                        </Layout>
                    </TracingProvider>
                </AnalyticsProvider>
            </RecoilRoot>
        </ThemeProvider>
    );
}

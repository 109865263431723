// Copyright 2022 Descript, Inc

import { RemoteAssetStore } from '../AssetSync/Types';
import { AssetSyncApi } from '../AssetSync/api';
import * as ApiClient from './ApiClient';
import { DescriptError, ErrorCategory, Errors } from '@descript/errors';

export class WebAssetSyncApi extends AssetSyncApi implements RemoteAssetStore {
    isAvailable(): boolean {
        // Can only use navigator.onLine on web
        return ApiClient.isLoggedIn() && navigator.onLine;
    }

    onlineInvariant() {
        if (!this.isAvailable()) {
            throw Errors.networkError(
                new DescriptError('We are offline right now', ErrorCategory.GlobalAssetSync),
            );
        }
    }
}
